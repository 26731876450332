:root {
  --color-transparent: transparent;
  --color-black: #000000;
  --color-white: #FFFFFF;

  --color-label-partial: #FF7761;
  --color-label-ongoing: #C4CAE7;
  --color-label-completed: #6EC68C;
  --color-label-archived: #006BC8;

  --color-gray-100: #FDFDFD;
  --color-gray-200: #F6F6F6;
  --color-gray-300: #ECECEC;
  --color-gray-400: #DFDFDF;
  --color-gray-500: #D7D7D7;
  --color-gray-600: #CCCCCC;
  --color-gray-700: #C4C4C4;
  --color-gray-800: #BFBFBF;
  --color-gray-900: #9A9A9A;
  --color-gray-1000: #9B9B9B;
  --color-gray-1100: #8B8B8B;
  --color-gray-1200: #8C8C8C;
  --color-gray-1300: #777777;
  --color-gray-1400: #7B7B7B;
  --color-gray-1500: #6B6C6E;
  --color-gray-1600: #505050;

  --color-brown-300: #C2B8A4;
  --color-brown-400: #898172;
  --color-brown-500: #706A5E;
  --color-brown-600: #6C624F;

  --color-yellow-100: #FFF5E5;
  --color-yellow-200: #FFCE80;
  --color-yellow-300: #FFB237;
  --color-yellow-400: #F29500;
  --color-yellow-500: #CB7D00;

  --color-orange-100: #FCD592;
  --color-orange-200: #FF9D00;
  --color-orange-300: #FF9900;
  --color-orange-400: #E48B01;
  --color-orange-700: #CB7D00;

  --color-green-100: #6EC68C;
  --color-green-200: #3EAE5D;
  --color-green-300: #359C52;
  --color-green-400: #03A88A;
  --color-green-500: #3FD33F;
  --color-green-600: #16A43E;

  --color-red-100: #FFBDBD;
  --color-red-200: #FF7761;
  --color-red-300: #FF0000;
  --color-red-400: #C42300;
  --color-red-500: #C7000C;
  --color-red-600: #BD0000;

  --color-blue-100: #397ADB;
  --color-blue-200: #006BC8;
  --color-blue-400: #0c7aa9;

}
