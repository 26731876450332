/* Set color of checkbox */
.Mui-disabled > .MuiIconButton-label > .MuiSvgIcon-root {
  fill: #d3d3d3;
}

span:not(.Mui-disabled) > .MuiIconButton-label > .MuiSvgIcon-root {
  fill: #f6ad55;
}

.error-checkbox:not(.Mui-disabled) > .MuiIconButton-label > .MuiSvgIcon-root {
  fill: #CB0000;
}
