@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Avenir.ttc');
}

:root {
  --main-font-size: 14px;
  --font-family-primary: 'Avenir';
}

html, body {
  height: 100%;
  background-color: var(--color-gray-100);
}